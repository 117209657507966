import React from 'react';
import Appbar from '../components/appbar';

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Input
} from 'reactstrap';
import {
  FaPaperPlane as PaperPlane
} from "react-icons/fa";

class Contact extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      subject: '', 
      body: '',
      formAction: ''
    };

    this.inputHandler = this.inputHandler.bind(this);
  }

  inputHandler = (event) => {
    let subject = this.state.subject;
    let body = this.state.body;

    if(event.target.id === 'subject'){
      subject = event.target.value;
    }
    else if(event.target.id === 'body'){
      body = event.target.value;
    }

    const formAction = `mailto:me@scotthansen.io?subject=${subject}&body=${body}`;

    this.setState({
      [event.target.id]: event.target.value,
      formAction: formAction
    });

  }

  render(){
    return (
      <div>
        <Appbar />
        <Container>
          <Row>
            <Col xs={{size:12}} lg={{size: 8, offset:2}} style={{marginTop: '10%'}}>
              <Form action={this.state.formAction}>
                Subject:
                <FormGroup>
                  <Input type="text" name="subject" id="subject" onChange={this.inputHandler} />
                </FormGroup>
                Body:
                <FormGroup>
                  <Input type="textarea" name="body" id="body" onChange={this.inputHandler} />
                </FormGroup>
              <Button>
                Let's get in touch. &nbsp; <PaperPlane />
              </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;